<template>

  <div>

    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">

        <b-row>

          <b-col cols="12">
            <b-table
              striped
              hover
              responsive
              :items="items"
              :fields="fields"
              style="min-height:500px"
            >

              <template #cell(actions)="row">
                <b-row>
                  <b-col cols="12">
                    <router-link
                      :to="{path:'/awards-appraise',query:{id:row.item.id}}"
                    >
                      <b-button
                        v-if="row.item.appraise_status === 1 "
                        variant="outline-primary"

                      >
                        点此开始评选
                      </b-button>
                    </router-link>
                  </b-col>
                </b-row>
              </template>
            </b-table>
          </b-col>
          <b-col
              cols="12"
          >
            <page
                :page="page"
                @getPage="getPage"
            />
          </b-col>
        </b-row>
      </div>
    </b-card>

  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, VBToggle, BButton, BBadge, BModal, BCardBody, BImg, VBTooltip, BAlert, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BBadge,
    BCard,
    BTable,
    BRow,
    BCol,
    BButton,
    BModal,
    BCardBody,
    BImg,
    BAlert,
    BDropdown,
    BDropdownItem,

  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
    Ripple,
  },
  data() {
    return {
      page: {
        currentPage: 1,
        totalRows: 0,
        pageSize:10,
      },
      updateId: 0,
      currentPage: 1,
      searchQuery: '',
      filter: null,
      filterOn: [],
      updateIndex: 0,
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [

        {
          key: 'title', label: '标题',
        },
        {
          key: 'awards_name', label: '奖项名称',
        },
        {
          key: 'number', label: '已申请人数',
        },
        {
          key: 'status_name', label: '申请状态',
        },
        {
          key: 'appraise_status_name', label: '评选状态',
        },

        { key: 'actions', label: '操作' },

      ],
      items: [],


    }
  },

  created() {
    this.getAwardsLists()
  },

  methods: {

    getPage(page) {
      console.log(page)
      this.page.current_page = page
      console.log(this.page.current_page)
      this.getAwardsLists()
    },

    getAwardsLists() {
      this.AwardsLists().then(res => {
        console.log(res)
        const { data } = res.data
        console.log(data)
        this.items = data.lists
        this.page.totalRows = data.total_count
      })
    },

    appraise(id){

    },








  },
}
</script>
